import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import ServerProvider from './components/server/server.component'
import PageHeader from './components/page-header/page-header.component'

import HomeScreen from './screens/home/home.screen'
import SignInScreen from './screens/signin/signin.screen'
import UsersScreen from './screens/users/users.screen'
import PageEditor from './screens/page-editor/page-editor.screen'
import AlbumsList from './screens/album-list/albums-list.screen'
import PageSelector from './screens/page-selector/page-selector.screen'
import StampsListScreen from './screens/stamps-list/stamps-list.screen'
import CatalogsScreen from './screens/catalogs/catalogs.screen'
import CatalogScreen from './screens/catalog/catalog.screen'
import ConflictsScreen from './screens/conflicts/conflicts.screen'
import StampDetailsScreen from './screens/stamp-details/stamp-details.screen'

import './App.css'

function App() {
  return (
    <ServerProvider>
      <Router>
        <PageHeader />
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/signin" component={SignInScreen} />
          <Route exact path="/users" component={UsersScreen} />

          <Route exact path="/albums" component={AlbumsList} />
          <Route exact path="/albums/:album/pages" component={PageSelector} />

          <Route exact path="/albums/:album/pages/:page" component={PageEditor} />

          <Route exact path="/catalogs" component={CatalogsScreen} />
          <Route exact path="/catalogs/:regionId/:year" component={CatalogScreen} />

          <Route exact path="/stamps" component={StampsListScreen} />
          <Route exact path="/stamps/:lfdnr" component={StampDetailsScreen} />

          <Route exact path="/stamps/conflicts" component={ConflictsScreen} />
        </Switch>
      </Router>
    </ServerProvider>
  )
}

export default App
