import React, { useState } from 'react'
import { Image, ToggleButtonGroup, ToggleButton, Button, Modal } from 'react-bootstrap'
import { baseURL } from '../server/server.component'

import styles from './page-image-editor.module.css'

var lastSize = { w: 10, h: 10 }

const PageImageEditor = props => {
  const canvasRef = React.useRef(null)
  const imgRef = React.useRef(null)
  const [selected, setSelected] = useState('pos')
  const [showMove, setShowMove] = useState(false)
  const [moveAlbum, setMoveAlbum] = useState('')
  const [movePage, setMovePage] = useState('')

  const moveStamp = stamp => {
    if (!moveAlbum || !movePage) {
      return
    }
    console.log('move stamp', moveAlbum, movePage)
    props.onMove({ lfdnr: stamp.lfdnr, album: moveAlbum, page: movePage })
    setShowMove(false)
  }

  const renderSelectedStamp = (stamp, selected, setSelected) => {
    if (!stamp) {
      return null
    }
    return (
      <>
        <div>
          <ToggleButtonGroup name="corner" value={selected} onChange={val => setSelected(val)}>
            <ToggleButton variant="secondary" value="pos">
              x={stamp.x} y={stamp.y}
            </ToggleButton>
            <ToggleButton variant="secondary" value="size">
              width={stamp.w} height={stamp.h}
            </ToggleButton>
          </ToggleButtonGroup>
          <span style={{ padding: 5 }}>{stamp.lfdnr}</span>
          <span style={{ padding: 5 }}>{stamp.region}</span>
          <span style={{ padding: 5 }}>{stamp.nr}</span>
          <span style={{ padding: 5 }}>{stamp.stempel}</span>
          <Button onClick={() => props.onDeleteSelected(stamp)}>Löschen</Button>
          <Button onClick={() => setShowMove(true)}>Verschieben</Button>
        </div>
        <Modal show={showMove} onHide={() => setShowMove(false)} dialogClassName={styles.myModal}>
          <Modal.Header className={styles.myModalHeader}>
            <Modal.Title className={styles.myModalTitle}>Marke {stamp.lfdnr} verschieben?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <input placeholder="Album" value={moveAlbum} onChange={({ target: { value } }) => setMoveAlbum(value)} />
              <input placeholder="Seite" value={movePage} onChange={({ target: { value } }) => setMovePage(value)} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowMove(false)}>
              Abbrechen
            </Button>
            <Button onClick={() => moveStamp(stamp)}>Ja</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  redraw(imgRef, canvasRef, props)
  return (
    <div>
      {renderSelectedStamp(props.selectedStamp, selected, setSelected)}
      <Image ref={imgRef} src={baseURL + '/albums/' + props.album + '/pages/' + props.page + '/photo'} style={{ display: 'none' }} onLoad={() => redraw(imgRef, canvasRef, props)} />

      <canvas
        tabIndex={1}
        ref={canvasRef}
        width={1368}
        height={1824}
        onClick={e => {
          e.preventDefault()
          if (selected) {
            var rect = e.target.getBoundingClientRect()
            var x = Math.round(((e.clientX - rect.left) / 1368.0) * (props.pageSize.width || 194.0))
            var y = Math.round(((e.clientY - rect.top) / 1824.0) * (props.pageSize.height || 291.0))

            console.log('click', x, y)
            if (props.selectedStamp) {
              if (selected === 'pos') {
                props.selectedStamp.x = x
                props.selectedStamp.y = y
              } else {
                props.selectedStamp.w = x - props.selectedStamp.x
                props.selectedStamp.h = y - props.selectedStamp.y
                lastSize.w = x - props.selectedStamp.x
                lastSize.h = y - props.selectedStamp.y
              }
              console.log('stamp', props.selectedStamp)
              props.onStampChanged(props.selectedStamp)
            }
            const canvas = canvasRef.current
            const ctx = canvas.getContext('2d')
            drawPage(ctx, props.data, props.pageSize, imgRef, props.selectedStamp)
          }
        }}
        onKeyPress={e => {
          const key = e.key
          console.log(key)
          if (key === 's') {
            setSelected('size')
          }
          if (key === 'p') {
            setSelected('pos')
          }
          if (key === 'n') {
            setSelected('pos')
            props.onNextStamp()
          }
          if (key === 'P') {
            setSelected('pos')
            props.onPrevStamp()
          }
          if (key === 'S') {
            props.selectedStamp.w = lastSize.w
            props.selectedStamp.h = lastSize.h
            props.onStampChanged(props.selectedStamp)
          }
          const canvas = canvasRef.current
          const ctx = canvas.getContext('2d')
          drawPage(ctx, props.data, props.pageSize, imgRef, props.selectedStamp)
        }}
      />
    </div>
  )
}

function redraw(imgRef, canvasRef, props) {
  const canvas = canvasRef.current
  if (canvas) {
    const ctx = canvas.getContext('2d')
    drawPage(ctx, props.data, props.pageSize, imgRef, props.selectedStamp)
  }
}

function drawPage(ctx, page, pageSize, imgRef, selectedStamp) {
  if (!pageSize) {
    return
  }
  ctx.drawImage(imgRef.current, 0, 0, 1368, 1824)
  const fx = 1368.0 / (pageSize.width || 194.0)
  const fy = 1824.0 / (pageSize.height || 291.0)
  page.marken?.forEach(stamp => {
    ctx.strokeStyle = stamp.lfdnr === (selectedStamp ? selectedStamp.lfdnr : 0) ? 'red' : 'blue'
    const x = (stamp.x || 10) * fx
    const y = (stamp.y || 10) * fy
    const w = (stamp.w || 10) * fx
    const h = (stamp.h || 10) * fy
    ctx.strokeRect(x, y, w, h)
  })
}

export default PageImageEditor
