/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Tabs, Tab, Button } from 'react-bootstrap'
import PagePhoto from '../../components/page-photo/page-photo.component'
import PagePreview from '../../components/page-preview/page-preview.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import { Server } from '../../components/server/server.component'
import StampList from '../../components/stamp-list/stamp-list.component'

const PageEditor = ({ match: { params } }) => {
  const { albums } = useContext(Server)
  const [album, setAlbum] = useState()
  const [data, setData] = useState()
  const history = useHistory()

  const albumId = params.album
  const page = parseInt(params.page)

  useEffect(() => {
    if (albumId) {
      albums.get(albumId, setAlbum)
    }
  }, [albums, albumId])

  useEffect(() => {
    if (albumId && page) {
      albums.pages.get(albumId, page, setData)
    }
  }, [albums, albumId, page])

  const prevPage = () => {
    const newPage = page > 1 ? page - 1 : page
    history.push('/albums/' + albumId + '/pages/' + newPage)
  }

  const nextPage = () => {
    const newPage = 1 + page
    history.push('/albums/' + albumId + '/pages/' + newPage)
  }

  if (!data || !album) {
    return <>Keine Daten vorhanden</>
  }

  return (
    <ScreenPanel>
      <h1>
        Album {params.album} Seite <Button onClick={prevPage}>&lt;</Button> {page} <Button onClick={nextPage}>&gt;</Button>
      </h1>
      <Tabs defaultActiveKey='list'>
        <Tab eventKey='list' title='Liste'>
          <StampList stampList={data.marken} album={params.album} page={page} reload={() => albums.pages.get(albumId, page, setData)} />
        </Tab>
        <Tab eventKey='preview' title='Vorschau'>
          <PagePreview album={album} data={data} onPageUpdated={setData} />
        </Tab>
        <Tab eventKey='photo' title='Foto'>
          <PagePhoto data={data.photo} />
        </Tab>
      </Tabs>
    </ScreenPanel>
  )
}
export default PageEditor
