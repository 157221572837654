/** @format */

import { useState } from 'react'
import { Button, Image, Modal } from 'react-bootstrap'
import { baseURL } from '../server/server.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import styles from './catalog-stamp.module.css'

const CatalogStamp = ({ stamp: { lfdNr, value, label, image }, onSelect, onMove }) => {
  const [showImage, setShowImage] = useState(false)
  return (
    <>
      <div className={styles.stamp} onClick={() => onSelect()}>
        <span>{lfdNr}</span>
        <span>{value}</span>
        <span>{label}</span>
        <span onClick={() => setShowImage(true)}>{image && <Image height={50} src={baseURL + '/stamps/' + image + '/image'} />}</span>
        {onMove && (
          <>
            <Button onClick={() => onMove(-1)}>
              <FontAwesomeIcon icon={faChevronUp} />
            </Button>
            <Button onClick={() => onMove(1)}>
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </>
        )}
      </div>
      <Modal show={showImage} onHide={() => setShowImage(false)} dialogClassName={styles.myModal}>
        <Modal.Header className={styles.myModalHeader}>
          <Modal.Title className={styles.myModalTitle}>
            {lfdNr} - {value} - {label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: "url('" + baseURL + '/stamps/' + image + "/image') no-repeat",
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}></Modal.Body>
      </Modal>{' '}
    </>
  )
}

export default CatalogStamp
