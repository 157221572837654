import React, { useState, useContext } from 'react'
import { withRouter } from 'react-router'
import { Col, Row, ListGroup } from 'react-bootstrap'
import PageImageEditor from '../page-image-editor/page-image-editor.component'
import { Server } from '../server/server.component'

const PagePreview = ({ album, data, match: { params }, onPageUpdated }) => {
  const { albums } = useContext(Server)
  const [selectedStamp, setSelectedStamp] = useState()

  const page = params.page
  const l = data.marken?.map(a => (
    <ListGroup.Item active={selectedStamp === a} key={a.lfdnr} onClick={_ => setSelectedStamp(a)}>
      {a.lfdnr} {a.region} {a.nr} {a.stempel}
    </ListGroup.Item>
  ))

  const moveStamp = target => {
    if (!target) {
      console.log('no stamp to move')
      return
    }
    const album = params.album
    const page = params.page
    albums.pages.stamps.move(album, page, target.lfdnr, target, onPageUpdated)
  }

  const stampChanged = stamp => {
    if (!stamp) {
      console.log('no stamp to save')
      return
    }
    const album = params.album
    const page = params.page
    albums.pages.stamps.update(album, page, stamp.lfdnr, stamp, () => {})
  }

  const deleteStamp = stamp => {
    console.log('deleteStamp', stamp)
    const album = params.album
    const page = params.page
    albums.pages.stamps.delete(album, page, stamp.lfdnr, onPageUpdated)
  }

  const nextStamp = _ => {
    const idx = data.marken.indexOf(selectedStamp)
    const newStamp = data.marken[idx === data.marken.length - 1 ? data.marken - 1 : idx + 1]
    setSelectedStamp(newStamp)
  }

  const prevStamp = _ => {
    const idx = data.marken.indexOf(selectedStamp)
    const newStamp = data.marken[idx === 0 ? 0 : idx - 1]
    setSelectedStamp(newStamp)
  }

  if (!album || !page) {
    return null
  }
  return (
    <Row>
      <Col md={2}>
        <ListGroup style={{ maxHeight: 1800, overflow: 'scroll' }}>{l}</ListGroup>
      </Col>
      <Col md={10}>
        <PageImageEditor selectedStamp={selectedStamp} data={data} pageSize={album.pageSize} album={params.album} page={page} onStampChanged={stampChanged} onDeleteSelected={deleteStamp} onNextStamp={nextStamp} onPrevStamp={prevStamp} onMove={moveStamp} />
      </Col>
    </Row>
  )
}

export default withRouter(PagePreview)
