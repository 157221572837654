import React, { useContext, useEffect, useState } from 'react'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import Paginator from '../../components/paginator/paginator.component'
import { Server } from '../../components/server/server.component'
import StampInfo from '../../components/stamp-info/stamp-info.component'

const ConflictsScreen = () => {
  const { stamps } = useContext(Server)
  const [items, setItems] = useState()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(30)
  const [p, setP] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [filterCat, setFilterCat] = useState('')
  const [filterNo, setFilterNo] = useState('')
  const [filtered, setFiltered] = useState()

  const sort = data => {
    data.sort((a, b) => a.lfdnr - b.lfdnr)
    setItems(data)
  }

  useEffect(() => {
    stamps.load(sort)
  }, [stamps])

  useEffect(() => {
    if (filtered) {
      let l = (filtered.length + pageSize - 1) / pageSize
      setPageCount(parseInt(l < 1 ? 1 : l))
      if (page >= l) {
        setPage(l - 1)
      }
    }
  }, [filtered, pageSize, page])

  useEffect(() => {
    if (filtered) {
      setP(filtered.slice(page * pageSize, (page + 1) * pageSize))
    }
  }, [filtered, page, pageSize])

  useEffect(() => {
    if (items) {
      let one = []
      let onePlus = {}
      let f = []
      items.forEach(item => {
        if (one.includes(item.lfdnr)) {
          f.push(onePlus[item.lfdnr])
          f.push(item)
        } else {
          one.push(item.lfdnr)
          onePlus[item.lfdnr] = item
        }
      })
      setFiltered(f)
    }
  }, [filterCat, filterNo, items])

  return (
    <ScreenPanel>
      Konflikte
      <Paginator current={page} onChange={setPage} start={0} end={(pageCount - 1) | 0} />
      <div>
        {p &&
          p.map((item, idx) => (
            <div>
              <StampInfo full key={item.lfdnr + '-' + idx} stamp={item}>
                <span>Verschieben</span>
              </StampInfo>
            </div>
          ))}
      </div>
    </ScreenPanel>
  )
}

export default ConflictsScreen
