import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { auth } from '../../firebase/firebase.utils';

var authStateHandler = null;
var initializing = true;

const CheckAccess = ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    const [roles, setRoles] = useState([]);
    const history = useHistory();

    const gotoSignIn = () => {
        history.replace("SignIn");
    }

    useEffect(() => {
        authStateHandler = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });
        initializing = false;
        return () => {
            authStateHandler();
        };
    });

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then(result => {
                setRoles(result.claims.roles);
            });
        }
    }, [currentUser])

    if (initializing) {
        return null;
    }

    if (currentUser && roles) {
        return children;
    }

    if (!currentUser) {
        return (
            <Container>
                <div>Du musst angemeldet sein, um Zugang zu dieser Seite zu erhalten.</div>
                <Button onClick={gotoSignIn}>Anmelden</Button>
            </Container>
        )
    }

    return (
        <Container>
            <div>Du wurdest noch nicht freigeschaltet. Wende dich an den Administrator.</div>
        </Container>
    )
}

export default CheckAccess;
