import React from 'react'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import { auth } from '../../firebase/firebase.utils'
import { useHistory } from 'react-router-dom'

import styles from './home.module.css'
import ScreenTitle from '../../components/screen-title/screen-title.component'

const HomeScreen = () => {
  const history = useHistory()

  return (
    <ScreenPanel>
      <ScreenTitle label="Willkommen beim Stamps Studio" />
      <div>
        <div className={styles.card} onClick={() => history.push('/albums')}>
          <div className={styles.title}>Alben</div>
          <div className={styles.body}>Zugang zu den Alben</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/stamps')}>
          <div className={styles.title}>Briefmarken</div>
          <div className={styles.body}>Zugang zu den Briefmarken</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/catalogs')}>
          <div className={styles.title}>Kataloge</div>
          <div className={styles.body}>Zugang zu den Katalogen</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/stamps/conflicts')}>
          <div className={styles.title}>Konflikte</div>
          <div className={styles.body}>Inkonsistente Daten korrigieren</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/users')}>
          <div className={styles.title}>Benutzer</div>
          <div className={styles.body}>Zugang geben</div>
        </div>
        {auth.currentUser && auth.currentUser.providerData[0].providerId === 'password' && (
          <div className={styles.card} onClick={() => history.push('/change-password')}>
            <div className={styles.title}>Einstellungen</div>
            <div className={styles.body}>Ändere hier dein Passwort</div>
          </div>
        )}
      </div>
    </ScreenPanel>
  )
}

export default HomeScreen
