import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Image, ToggleButton, ToggleButtonGroup, Button } from 'react-bootstrap'
import RangeSlider from 'react-bootstrap-range-slider'
import { baseURL, Server } from '../server/server.component'

const PagePhoto = ({ data, match: { params } }) => {
  const { albums } = useContext(Server)
  const [zoom, setZoom] = useState(40)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [lo, setLo] = useState({ x: 0, y: 0 })
  const [ro, setRo] = useState({ x: 10, y: 0 })
  const [lu, setLu] = useState({ x: 0, y: 10 })
  const [ru, setRu] = useState({ x: 10, y: 10 })
  const [photoName, setPhotoName] = useState()
  const [selectedCorner, setSelectedCorner] = useState('lo')
  const [originalWidth, setOriginalWidth] = useState()
  const [originalHeight, setOriginalHeight] = useState()
  const album = params.album
  const page = params.page

  const pad = (num, size) => {
    var s = '000000000' + num
    return s.substr(s.length - size)
  }

  useEffect(() => {
    console.log('data, album or page changed')
    if (data) {
      if (data.topleft) {
        setLo(data.topleft)
      }
      if (data.topright) {
        setRo(data.topright)
      }
      if (data.bottomleft) {
        setLu(data.bottomleft)
      }
      if (data.bottomright) {
        setRu(data.bottomright)
      }
      setPhotoName(data.name ? data.name : album + '_' + pad(page, 3) + '.jpg')
    } else {
      setPhotoName(album + '_' + pad(page, 3) + '.jpg')
    }
  }, [data, album, page])

  const clicked = e => {
    var rect = e.target.getBoundingClientRect()
    var x = Math.round(((e.clientX - rect.left) * 100) / zoom) //x position within the element.
    var y = Math.round(((e.clientY - rect.top) * 100) / zoom) //y position within the element.
    console.log(x, y)
    if (selectedCorner === 'lo') {
      setLo({ x, y })
    }
    if (selectedCorner === 'ro') {
      setRo({ x, y })
    }
    if (selectedCorner === 'lu') {
      setLu({ x, y })
    }
    if (selectedCorner === 'ru') {
      setRu({ x, y })
    }
  }

  const initZoom = evt => {
    console.log(evt)
    setOriginalWidth(evt.target.width)
    setOriginalHeight(evt.target.height)
    setWidth((evt.target.width * zoom) / 100)
    setHeight((evt.target.height * zoom) / 100)
  }

  const adjustZoom = evt => {
    const zoom = evt.target.value
    setZoom(zoom)
    setWidth((originalWidth * zoom) / 100)
    setHeight((originalHeight * zoom) / 100)
  }

  const save = () => {
    var photo = data
    if (!photo) {
      photo = {
        name: album + '_' + pad(page, 3) + '.jpg',
      }
    }
    photo.topleft = lo
    photo.topright = ro
    photo.bottomleft = lu
    photo.bottomright = ru
    albums.pages.photo.save(album, page, photo, () => {})
  }

  if (!photoName) {
    return null
  }
  return (
    <>
      <RangeSlider min={10} max={400} value={zoom} inputProps={{ style: { width: '100%' } }} onChange={adjustZoom} />
      <div>
        <ToggleButtonGroup name="corner" value={selectedCorner} onChange={setSelectedCorner}>
          <ToggleButton variant="secondary" value="lo">
            LO ({lo.x}, {lo.y})
          </ToggleButton>
          <ToggleButton variant="secondary" value="ro">
            RO ({ro.x}, {ro.y})
          </ToggleButton>
          <ToggleButton variant="secondary" value="lu">
            LU ({lu.x}, {lu.y})
          </ToggleButton>
          <ToggleButton variant="secondary" value="ru">
            RU ({ru.x}, {ru.y})
          </ToggleButton>
        </ToggleButtonGroup>{' '}
        <Button variant="primary" onClick={save}>
          Speichern
        </Button>
      </div>
      <Image width={width} height={height} src={baseURL + '/photos/' + photoName} onClick={clicked} />
      <Image style={{ display: 'none' }} src={baseURL + '/photos/' + photoName} onLoad={initZoom} />
    </>
  )
}

export default withRouter(PagePhoto)
