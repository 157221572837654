import React, { useState } from 'react'
import Signin from '../../components/signin/signin.component';
import Signup from '../../components/signup/signup.component';
import { useHistory } from "react-router-dom";
import { auth } from '../../firebase/firebase.utils';

import styles from './signin.module.css';

const SignInScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    console.log("current user in signin screen", auth.currentUser);
    if (auth.currentUser) {
        history.replace('/');
        return null;
    }
    return (
        <div className={styles.signinScreen}>
            <Signin />
            <Signup />
        </div>
    )
}

export default SignInScreen
