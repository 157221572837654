/** @format */

import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CatalogStamp from '../../components/catalog-stamp/catalog-stamp.component'
import EditableInput from '../../components/editable-input/editable-input.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import { Server, baseURL } from '../../components/server/server.component'
import { Image } from 'react-bootstrap'

import styles from './stamp-details.module.css'

const StampDetailsScreen = () => {
  const { lfdnr } = useParams()
  const { stamps, catalogs } = useContext(Server)
  const [stamp, setStamp] = useState()
  const [set, setSet] = useState()
  const [catalogItems, setCatalogItems] = useState()
  const [newYear, setNewYear] = useState()
  const [selectedCatalog, setSelectedCatalog] = useState()

  useEffect(() => {
    stamps.get(lfdnr, setStamp)
  }, [lfdnr, stamps])

  useEffect(() => {
    if (stamp && stamp.nr) {
      catalogs.sets.findByNumber(stamp.region, stamp.nr, setSet)
    }
  }, [catalogs, stamp])

  const loadCatalogs = () => {
    catalogs.load(stamp.region, setCatalogItems)
  }

  const saveStamp = () => {
    stamps.save(stamp, setStamp)
  }

  const addYear = year => {
    console.log('add year', year)
    const c = catalogItems.find(item => item.year === year)
    if (c) {
      setSelectedCatalog(c)
    } else {
      catalogs.save({ id: `${stamp.region}-${year}`, region: { id: stamp.region }, year: year, sets: [] }, cat => {
        setSelectedCatalog(cat)
        loadCatalogs()
      })
    }
  }

  if (!stamp) {
    return null
  }
  return (
    <ScreenPanel>
      <div className={styles.row}>
        <div>LfdNr</div>
        <div style={{ flex: 5 }}>{stamp.lfdnr}</div>
      </div>
      <div className={styles.row}>
        <div>Region</div>
        <EditableInput
          placeholder='Region'
          value={stamp.region}
          onChange={newValue => {
            stamp.region = newValue
            saveStamp()
          }}
        />
      </div>
      <div className={styles.row}>
        <div>Nummer</div>
        <EditableInput
          placeholder='Nummer'
          value={stamp.nr}
          onChange={newValue => {
            stamp.nr = newValue
            saveStamp()
          }}
        />
      </div>
      <div className={styles.row}>
        <div>Stempel</div>
        <EditableInput
          placeholder='Stempel'
          value={stamp.stempel}
          onChange={newValue => {
            stamp.stempel = newValue
            saveStamp()
          }}
        />
      </div>
      <div className={styles.row}>
        <div>X</div>
        <EditableInput
          placeholder='X'
          value={stamp.x}
          onChange={newValue => {
            stamp.x = newValue
            saveStamp()
          }}
        />
      </div>
      <div className={styles.row}>
        <div>Y</div>
        <EditableInput
          placeholder='Y'
          value={stamp.y}
          onChange={newValue => {
            stamp.y = newValue
            saveStamp()
          }}
        />
      </div>
      <div className={styles.row}>
        <div>Breite</div>
        <EditableInput
          placeholder='Breite'
          value={stamp.w}
          onChange={newValue => {
            stamp.w = newValue
            saveStamp()
          }}
        />
      </div>
      <div className={styles.row}>
        <div>Höhe</div>
        <EditableInput
          placeholder='Höhe'
          value={stamp.h}
          onChange={newValue => {
            stamp.h = newValue
            saveStamp()
          }}
        />
      </div>
      <span>
        <Image height={150} src={baseURL + '/stamps/' + stamp.lfdnr + '/image'} />
      </span>
      {set ? (
        <div className={styles.set}>
          <Link to={`/catalogs/${set.region}/${set?.year}?setId=${set.id}`}>
            <div className={styles.row}>
              <div>{set.releaseDate}</div>
              <div>{set.name}</div>
            </div>
          </Link>
          {set.stamps?.map(stamp => (
            <CatalogStamp key={stamp.id} stamp={stamp} onSelect={() => {}} />
          ))}
        </div>
      ) : (
        <div className={styles.set}>
          {!catalogItems && (
            <div style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
              Im Katalog nicht gefunden! <button onClick={loadCatalogs}>Anlegen</button>
            </div>
          )}
          {catalogItems && (
            <>
              <div>In Katalog aufnehmen: Ziel auswählen / erstellen</div>
              <div className={styles.yearSelect}>
                {catalogItems.map(catalog => (
                  <span
                    className={selectedCatalog?.id === catalog.id ? styles.selected : ''}
                    onClick={() => {
                      setSelectedCatalog(catalog)
                      console.log('setSelectedCatalog', catalog)
                    }}>
                    {catalog.year}
                  </span>
                ))}
                <input
                  placeholder='Neuer Jahrgang'
                  onChange={({ target: { value } }) => setNewYear(value)}
                  onKeyDown={({ key, target: { value } }) => {
                    if (key === 'Enter') {
                      addYear(value)
                    }
                  }}
                />
              </div>
              <div>
                {selectedCatalog &&
                  selectedCatalog.sets?.map(set => (
                    <div
                      onClick={() => {
                        set.stamps.push({ lfdNr: stamp.nr, image: stamp.lfdnr })
                        catalogs.save(selectedCatalog, cat => {
                          setSelectedCatalog(cat)
                          loadCatalogs()
                          catalogs.sets.findByNumber(stamp.region, stamp.nr, setSet)
                        })
                      }}>
                      {set.releaseDate} - {set.name}
                    </div>
                  ))}
                {selectedCatalog && (
                  <button
                    onClick={() => {
                      selectedCatalog.sets.push({ releaseDate: selectedCatalog.year, name: 'Neuer Satz', stamps: [{ lfdNr: stamp.nr, image: stamp.lfdnr }] })
                      catalogs.save(selectedCatalog, cat => {
                        setSelectedCatalog(cat)
                        loadCatalogs()
                        catalogs.sets.findByNumber(stamp.region, stamp.nr, setSet)
                      })
                    }}>
                    Neuer Satz
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </ScreenPanel>
  )
}

export default StampDetailsScreen
