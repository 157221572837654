import React, { useState } from 'react'
import { Image, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { baseURL } from '../server/server.component'

import styles from './stamp-info.module.css'

const StampInfo = ({ stamp, filter, filterCat, filterNo, onFilterCatChanged, onFilterNoChanged, full, children }) => {
  const [showImage, setShowImage] = useState(false)
  if (filter) {
    return (
      <div className={styles.header}>
        <div className={styles.stamp}>
          <span>Lfd.Nr.</span>
          {full && <span>Album / Seite</span>}
          <span>
            <input placeholder="Region" value={filterCat} onChange={({ target: { value } }) => onFilterCatChanged(value)} />
          </span>
          <span>
            <input placeholder="Nummer" value={filterNo} onChange={({ target: { value } }) => onFilterNoChanged(value)} />
          </span>
          <span>Stempel</span>
          <span>Bild</span>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className={styles.stamp}>
        <span>
          <Link to={`/stamps/${stamp.lfdnr}`}>{stamp.lfdnr}</Link>
        </span>
        {full && (
          <span>
            <Link to={`/albums/${stamp.album}/pages/${stamp.page}`}>
              {stamp.album} / {stamp.page}
            </Link>
          </span>
        )}
        <span>{stamp.region}</span>
        <span>{stamp.nr}</span>
        <span>{stamp.stempel}</span>
        <span onClick={() => setShowImage(true)}>
          <Image height={50} src={baseURL + '/stamps/' + stamp.lfdnr + '/image'} />
        </span>
        {children}
      </div>
      <Modal show={showImage} onHide={() => setShowImage(false)} dialogClassName={styles.myModal}>
        <Modal.Header className={styles.myModalHeader}>
          <Modal.Title className={styles.myModalTitle}>
            {stamp.lfdnr} - {stamp.region} {stamp.nr} {stamp.stempel}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: "url('" + baseURL + '/stamps/' + stamp.lfdnr + "/image') no-repeat",
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        ></Modal.Body>
      </Modal>
    </>
  )
}

export default StampInfo
