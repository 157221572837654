/** @format */

import React, { createContext } from 'react'
import { auth } from '../../firebase/firebase.utils'

export const Server = createContext()

export const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://stamps.beckers.cf/api'

var lastToken = null

function imagePathWithToken(name) {
  return `${baseURL}/articles/images/${name}?token=${lastToken}`
}

function executeGet(url, query, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    fetch(_url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return null
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executeQuery(type, query, callback, errorCallback) {
  if (query) executeGet('/' + type, { q: query }, callback, errorCallback)
  else executeGet('/' + type, null, callback, errorCallback)
}

function executePost(url, query, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    console.log('executePost ' + url, body)
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    }
    if (body) {
      requestOptions.body = JSON.stringify(body)
    }
    console.log('executePost ' + url, requestOptions.body)
    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    fetch(_url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          response.json().then(result => callback(result))
          return
        }
        if (response.status === 204) {
          console.log('status 204')
          callback({})
          return
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          try {
            console.log('error with json')
            response
              .json()
              .then(error => errorCallback(error))
              .catch(error => errorCallback(error))
            return
          } catch (e) {
            console.log('+++++++++++++++++++++++++')
          }
        }
        console.log('error without json')
        response
          .text()
          .then(text => errorCallback({ message: text }))
          .catch(error => errorCallback(error))
      })
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executePostForm(url, form, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: form,
    }

    fetch(baseURL + url, requestOptions)
      .then(response => {
        if (response.status === 200) {
          response.json().then(result => callback(result))
          return
        }
        if (response.status === 204) {
          callback({})
          return
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          try {
            response
              .json()
              .then(error => errorCallback(error))
              .catch(error => errorCallback(error))
            return
          } catch (e) {
            console.log('+++++++++++++++++++++++++')
          }
        }
        response
          .text()
          .then(text => errorCallback({ message: text }))
          .catch(error => errorCallback(error))
      })
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executePut(url, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    }
    fetch(baseURL + url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executeDelete(url, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(baseURL + url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function getItem(type, id, callback, errorCallback) {
  executeGet(`/${type}/${id}`, null, callback, errorCallback)
}

function createItem(type, body, callback, errorCallback) {
  executePost(`/${type}`, null, body, callback, errorCallback)
}

function updateItem(type, id, body, callback, errorCallback) {
  executePut(`/${type}/${id}`, body, callback, errorCallback)
}

const ServerProvider = ({ children }) => {
  return (
    <Server.Provider
      value={{
        baseURL,
        image: name => imagePathWithToken(name),
        albums: {
          query: (callback, errorCallback) => executeQuery('albums', null, callback, errorCallback),
          get: (id, callback) => getItem('albums', id, callback, console.error),
          pages: {
            load: (id, callback) => executeGet(`/albums/${id}/pages`, null, callback, console.error),
            get: (id, page, callback) => executeGet(`/albums/${id}/pages/${page}`, null, callback, console.error),
            stamps: {
              update: (album, page, stamp, body, callback) => executePost(`/albums/${album}/pages/${page}/stamps/${stamp}`, null, body, callback, console.error),
              move: (album, page, stamp, body, callback) => executePost(`/albums/${album}/pages/${page}/stamps/${stamp}/move`, null, body, callback, console.error),
              delete: (album, page, stamp, callback) => executeDelete(`/albums/${album}/pages/${page}/stamps/${stamp}`, callback, console.error),
            },
            photo: {
              save: (album, page, body, callback) => executePost(`/albums/${album}/pages/${page}/photo`, null, body, callback, console.error),
            },
          },
        },
        catalogs: {
          load: (regionId, callback) => executeGet(`/catalogs/${regionId}`, null, callback, console.error),
          get: (regionId, year, callback) => executeGet(`/catalogs/${regionId}/years/${year}`, null, callback, console.error),
          save: (body, callback) => executePost(`/catalogs`, null, body, callback, console.error),
          sets: {
            findByNumber: (regionId, number, callback) => executeGet(`/catalogs/${regionId}/numbers/${number}`, null, callback, console.error),
          },
        },
        regions: {
          load: callback => executeQuery('regions', null, callback, console.error),
          save: (body, callback) => executePost(`/regions`, null, body, callback, console.error),
        },
        stamps: {
          load: callback => executeQuery('stamps', null, callback, console.error),
          get: (lfdnr, callback) => getItem('stamps', lfdnr, callback, console.error),
          save: (body, callback) => createItem('stamps', body, callback, console.error),
          create: (album, page, callback) => executePost(`/stamps/${album}/pages/${page}`, null, null, callback, console.error),
        },
        users: {
          query: (callback, errorCallback) => executeQuery('users', null, callback, errorCallback),
          setRoles: (userId, body, callback, errorCallback) => executePost(`/users/${userId}`, null, body, callback, errorCallback),
          init: (userId, realName, callback, errorCallback) => executePost(`/users/${userId}/init`, null, { realName }, callback, errorCallback),
          password: (userId, password, callback, errorCallback) => executePost(`/users/${userId}/password`, null, { password }, callback, errorCallback),
        },
      }}>
      {children}
    </Server.Provider>
  )
}

export default ServerProvider
