import React, { useState, useEffect } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Link } from 'react-router-dom'
import { auth } from '../../firebase/firebase.utils'
import Restrict from '../restrict/restrict.component'
import { useHistory } from 'react-router-dom'

import styles from './page-header.module.css'

var authStateHandler = null

const PageHeader = () => {
  const [currentUser, setCurrentUser] = useState()
  const [roles, setRoles] = useState([])
  const history = useHistory()

  useEffect(() => {
    authStateHandler = auth.onAuthStateChanged(user => {
      console.log('auth state changed', user)
      setCurrentUser(user)
    })
    return () => {
      authStateHandler()
    }
  })

  useEffect(() => {
    if (currentUser) {
      console.log('lookup roles')
      currentUser.getIdTokenResult().then(result => {
        console.log('got roles')
        setRoles(result.claims.roles)
      })
    }
  }, [currentUser])

  const signOut = async () => {
    await auth.signOut()
    history.replace('/')
  }

  const popover = currentUser ? (
    <Popover>
      <Popover.Title>Angemeldet als</Popover.Title>
      <Popover.Content>
        <div>{currentUser.displayName}</div>
        <div>{currentUser.email}</div>
        <div>{JSON.stringify(roles)}</div>
      </Popover.Content>
    </Popover>
  ) : null

  const a = currentUser ? (
    <>
      <Restrict allowed={['user', 'admin']} roles={roles}>
        <Link to="/albums">
          <div>Alben</div>
        </Link>
        <Link to="/stamps">
          <div>Briefmarken</div>
        </Link>
        <Link to="/catalogs">
          <div>Kataloge</div>
        </Link>
      </Restrict>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="bottom-end" overlay={popover}>
        <div onClick={signOut} style={{ cursor: 'pointer' }}>
          Abmelden
        </div>
      </OverlayTrigger>
    </>
  ) : (
    <Link to="/signin">
      <div>Anmelden</div>
    </Link>
  )

  return (
    <div className={styles.pageHeader}>
      <Link to="/">
        <div className={styles.home}>Stamps Studio</div>
      </Link>
      {a}
    </div>
  )
}

export default PageHeader
