import { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { baseURL } from '../server/server.component'

import styles from './editable-label.module.css'

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const EditableLabel = ({ childRef, text, type, placeholder, children, onReset, onAccept, ...props }) => {
  // Manage the state whether to show the label or the input box. By default, label will be shown.
  // Exercise: It can be made dynamic by accepting initial state as props outside the component
  const [isEditing, setEditing] = useState(false)

  useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus()
    }
  }, [isEditing, childRef])

  // Event handler while pressing any key while editing
  const handleKeyDown = (event, type) => {
    const { key } = event
    const keys = ['Escape', 'Tab']
    const enterKey = 'Enter'
    const allKeys = [...keys, enterKey] // All keys array

    /* 
    - For textarea, check only Escape and Tab key and set the state to false
    - For everything else, all three keys will set the state to false
  */
    if ((type === 'textarea' && keys.indexOf(key) > -1) || (type !== 'textarea' && allKeys.indexOf(key) > -1)) {
      if (key === 'Escape') {
        onReset?.()
      } else {
        onAccept?.()
      }
      setEditing(false)
    }
  }

  /*
- It will display a label is `isEditing` is false
- It will display the children (input or textarea) if `isEditing` is true
- when input `onBlur`, we will set the default non edit mode
Note: For simplicity purpose, I removed all the classnames, you can check the repo for CSS styles
*/
  return (
    <section className={styles.editable} {...props}>
      {isEditing ? (
        <div onBlur={() => setEditing(false)} onKeyDown={e => handleKeyDown(e, type)}>
          {children}
        </div>
      ) : (
        <div onClick={() => setEditing(true)}>
          {!text && <span className={styles.placeholder}>{placeholder || 'Keine Angabe'}</span>}
          {type !== 'image' && <span>{text}</span>}
          {type === 'image' && text && <Image height={250} src={baseURL + '/stamps/' + text + '/image'} />}
        </div>
      )}
    </section>
  )
}

export default EditableLabel
