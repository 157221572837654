/** @format */

import { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import CatalogStamp from '../../components/catalog-stamp/catalog-stamp.component'
import EditableImage from '../../components/editable-image/editable-image.component'
import EditableInput from '../../components/editable-input/editable-input.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import { Server } from '../../components/server/server.component'

import styles from './catalog.module.css'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const CatalogsScreen = ({
  match: {
    params: { regionId, year },
  },
}) => {
  const query = useQuery()
  const querySetId = query.get('setId')

  const { catalogs } = useContext(Server)
  const [catalog, setCatalog] = useState()
  const [selectedSet, setSelectedSet] = useState()
  const [selectedSetId, setSelectedSetId] = useState()
  const [selectedStamp, setSelectedStamp] = useState()
  const [selectedStampId, setSelectedStampId] = useState()

  useEffect(() => {
    setSelectedSetId(querySetId)
  }, [querySetId])

  useEffect(() => {
    catalogs.get(regionId, year, setCatalog)
  }, [regionId, year, catalogs])

  useEffect(() => {
    if (catalog && selectedSetId) {
      setSelectedSet(catalog.sets.find(e => e.id === selectedSetId))
    }
  }, [catalog, selectedSetId])

  useEffect(() => {
    if (selectedSet && selectedSet.stamps && selectedStampId) {
      setSelectedStamp(selectedSet.stamps.find(x => x.id === selectedStampId))
    }
  }, [selectedSet, selectedStampId])

  const saveCatalog = () => {
    catalogs.save(catalog, saved => {
      setCatalog(saved)
      if (selectedSetId) {
        const s = catalog.sets.find(e => e.id === selectedSetId)
        setSelectedSet(s)
        if (selectedStampId) {
          setSelectedStamp(s.stamps.find(x => x.id === selectedStampId))
        } else {
          setSelectedStamp(null)
        }
      } else {
        setSelectedSet(null)
      }
    })
  }

  const moveStamp = (idx, direction) => {
    const l = selectedSet.stamps
    if (idx + direction < 0 || idx + direction >= l.length) {
      return
    }
    console.log('move stamp', idx, direction, l)
    if (direction < 0) {
      const a = l[idx]
      const b = l[idx + direction]
      l.splice(idx + direction, 2, a, b)
      setSelectedSet({ ...selectedSet })
      console.log('>move stamp', idx, direction, selectedSet.stamps)
    } else {
      const a = l[idx]
      const b = l[idx + direction]
      l.splice(idx, 2, b, a)
      setSelectedSet({ ...selectedSet })
      console.log('>move stamp', idx, direction, selectedSet.stamps)
    }
    saveCatalog()
  }

  return (
    <ScreenPanel>
      <div>
        {regionId} - {catalog?.region?.name} - {year}
      </div>
      <div>Letzte Änderung: {catalog?.lastModification}</div>

      <div className={styles.twoColumns}>
        <div className={styles.setSelect}>
          <button
            onClick={() => {
              catalog.sets.push({ releaseDate: `1.1.${catalog.year}`, name: 'Neuer Satz' })
              saveCatalog()
            }}>
            Neuer Satz
          </button>
          {catalog?.sets?.map(set => (
            <div key={set.id} className={set.id === selectedSetId ? styles.active : ''}>
              <Link to={`/catalogs/${regionId}/${year}?setId=${set.id}`}>
                {set.releaseDate} - {set.name}
              </Link>
            </div>
          ))}
        </div>
        <div>
          {selectedSet && (
            <>
              <button
                onClick={() => {
                  if (!selectedSet.stamps) {
                    selectedSet.stamps = []
                  }
                  selectedSet.stamps.push({ lfdNr: 'Neu' })
                  console.log('added stamp', catalog)
                  saveCatalog()
                }}>
                Neue Marke
              </button>
              <div className={styles.row}>
                <EditableInput
                  placeholder='Veröffentlichung'
                  value={selectedSet.releaseDate}
                  onChange={newValue => {
                    selectedSet.releaseDate = newValue
                    saveCatalog()
                  }}
                />
                <EditableInput
                  placeholder='Titel'
                  value={selectedSet.name}
                  onChange={newValue => {
                    selectedSet.name = newValue
                    saveCatalog()
                  }}
                />
              </div>
              {selectedSet.stamps?.map((stamp, idx) => (
                <CatalogStamp
                  key={stamp.id}
                  stamp={stamp}
                  onSelect={() => {
                    setSelectedStamp(stamp)
                    setSelectedStampId(stamp.id)
                  }}
                  onMove={direction => moveStamp(idx, direction)}
                />
              ))}
            </>
          )}
          {selectedStamp && (
            <div className={styles.stampDetail}>
              <div className={styles.row}>
                <div>LfdNr</div>
                <EditableInput
                  placeholder='LfdNr'
                  value={selectedStamp.lfdNr}
                  onChange={newValue => {
                    selectedStamp.lfdNr = newValue
                    saveCatalog()
                  }}
                />
              </div>
              <div className={styles.row}>
                <div>Nominale</div>
                <EditableInput
                  placeholder='Nominale'
                  value={selectedStamp.value}
                  onChange={newValue => {
                    selectedStamp.value = newValue
                    saveCatalog()
                  }}
                />
              </div>
              <div className={styles.row}>
                <div>Beschreibung</div>
                <EditableInput
                  placeholder='Beschreibung'
                  value={selectedStamp.label}
                  onChange={newValue => {
                    selectedStamp.label = newValue
                    saveCatalog()
                  }}
                />
              </div>
              <div className={styles.row}>
                <div>Bild</div>
                <EditableImage
                  placeholder='Bild'
                  value={selectedStamp.image}
                  onChange={newValue => {
                    selectedStamp.image = newValue
                    saveCatalog()
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </ScreenPanel>
  )
}

export default CatalogsScreen
