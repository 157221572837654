import { useState } from 'react'

import styles from './region-tree.module.css'

const RegionTree = ({ tree, onSelect }) => {
  const [opened, setOpened] = useState(false)
  return (
    <div className={styles.tree}>
      <div>
        {tree.children && (
          <span onClick={() => setOpened(!opened)} className={styles.toggle}>
            {opened ? 'v' : '>'}
          </span>
        )}
        <span onClick={() => onSelect(tree.region)} className={styles.label}>
          {tree.region.name ?? tree.region.id}
        </span>
      </div>
      {opened && (
        <div className={styles.subtree}>
          {tree.children?.map((child, idx) => (
            <RegionTree key={idx} tree={child} onSelect={onSelect} />
          ))}
        </div>
      )}
    </div>
  )
}

export default RegionTree
