import React, { useContext, useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router'
import { FormControl } from 'react-bootstrap'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'

import styles from './page-selector.module.css'
import { Server } from '../../components/server/server.component'

const PageSelector = props => {
  const { albums } = useContext(Server)
  const [data, setData] = useState()
  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState([])
  const history = useHistory()

  useEffect(() => {
    albums.pages.load(props.match.params.album, setData)
  }, [albums, props.match.params.album])

  useEffect(() => {
    if (data) {
      setFiltered(data.filter(a => ('' + a).includes(filter)))
    }
  }, [data, filter])

  if (!data) {
    return <>Keine Daten vorhanden</>
  }

  const selectPage = (evt, page) => {
    history.push('/albums/' + props.match.params.album + '/pages/' + page)
  }

  const l = filtered.map(a => (
    <div className={styles.page} key={a} onClick={e => selectPage(e, a)}>
      {a}
    </div>
  ))
  return (
    <ScreenPanel>
      <ScreenTitle label={`Album ${props.match.params.album}`} />
      <div>Seite auswählen</div>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" value={filter} onChange={({ target: { value } }) => setFilter(value)} />
      <div>{l}</div>
    </ScreenPanel>
  )
}

export default withRouter(PageSelector)
