/** @format */

import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { Server } from '../server/server.component'
import StampInfo from '../stamp-info/stamp-info.component'

const StampList = ({ stampList, album, page, reload }) => {
  const { stamps } = useContext(Server)
  return (
    <div>
      {stampList && stampList.map(stamp => <StampInfo key={stamp.lfdnr} stamp={stamp} />)}
      <Button
        onClick={() => {
          stamps.create(album, page, reload)
        }}>
        Neue Marke
      </Button>
    </div>
  )
}

export default StampList
