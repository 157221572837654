import React, { useContext, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { useHistory } from "react-router";
import ScreenPanel from "../../components/screen-panel/screen-panel.component";
import { Server } from "../../components/server/server.component";

import styles from './album-list.module.css';

const AlbumsList = () => {
    const { albums } = useContext(Server);
    const history = useHistory();
    const [data, setData] = useState();
    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        albums.query(data => {
            setData(data);
            setFiltered(data);
        }, console.error);
    }, [albums]);

    const filter = (evt) => {
        const txt = evt.target.value;
        setFiltered(data.filter(a => a.name.includes(txt)));
    }

    const selectAlbum = (evt, album) => {
        history.push("/albums/" + album.name + "/pages");
    }

    if (!data) {
        return (<>Keine Daten vorhanden</>);
    }
    const l = filtered.map(a => <div className={styles.album} key={a.name} onClick={e => selectAlbum(e, a)}>{a.name}</div>);
    return <ScreenPanel>
        <h2>Album auswählen</h2>
        <FormControl type="text" placeholder="Name filtern" className="mr-sm-2" onChange={e => filter(e)} />
        <div className={styles.albumList}>{l}</div>
    </ScreenPanel>;

}

export default AlbumsList;