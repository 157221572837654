import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import EditableInput from '../../components/editable-input/editable-input.component'
import RegionTree from '../../components/region-tree/region-tree.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import { Server } from '../../components/server/server.component'

import styles from './catalogs.module.css'

const CatalogsScreen = () => {
  const { regions, catalogs } = useContext(Server)
  const [tree, setTree] = useState()
  const [selectedRegion, setSelectedRegion] = useState()
  const [items, setItems] = useState()
  const history = useHistory()
  const inputRef = useRef()

  useEffect(() => {
    regions.load(setTree)
  }, [regions])

  useEffect(() => {
    if (selectedRegion) {
      catalogs.load(selectedRegion.id, setItems)
    }
  }, [selectedRegion, catalogs])

  const selectCatalog = item => {
    history.push(`/catalogs/${selectedRegion.id}/${item.year}`)
  }

  const saveRegion = name => {
    regions.save({ ...selectedRegion, name }, setSelectedRegion)
  }

  return (
    <ScreenPanel>
      <div>Catalogs</div>
      <div className={styles.twoColumns}>
        <div>
          {tree?.children?.map(t => (
            <RegionTree key={t.region.id ?? 'world'} tree={t} onSelect={setSelectedRegion} />
          ))}
        </div>
        <div>
          {selectedRegion && (
            <div className={styles.region}>
              <div>{selectedRegion.id}</div>
              <EditableInput
                placeholder="Name"
                value={selectedRegion.name}
                onChange={newValue => {
                  saveRegion(newValue)
                }}
              />
            </div>
          )}
          <div>
            {items?.map(item => (
              <div key={item.id} onClick={() => selectCatalog(item)} className={styles.year}>
                {item.year}
              </div>
            ))}
            {selectedRegion && <button>Jahr hinzufügen</button>}
          </div>
        </div>
      </div>
    </ScreenPanel>
  )
}

export default CatalogsScreen
