import { useEffect, useState } from 'react'
import styles from './paginator.module.css'

const Paginator = ({ current, onChange, start, end }) => {
  const [pageIndices, setPageIndices] = useState([])
  let s = current - 3
  let e = current + 4

  if (s <= start) s = start + 1
  if (e >= end) e = end

  useEffect(() => {
    console.log(e, s, e - s)
    if (s > e) {
      setPageIndices([])
    } else {
      setPageIndices([...Array((e - s) | 0).keys()])
    }
  }, [s, e, current])

  if (start === end) {
    return null
  }
  return (
    <div className={styles.paginator}>
      {current > start && <span onClick={() => onChange(current - 1)}>{'<'}</span>}
      <span className={start === current ? styles.selected : ''} onClick={() => onChange(start)}>
        {start}
      </span>
      {s > start + 1 && <span>...</span>}
      {pageIndices &&
        pageIndices.map(idx => (
          <span className={idx + s === current ? styles.selected : ''} key={idx} onClick={() => onChange(idx + s)}>
            {idx + s}
          </span>
        ))}
      {e < end && <span>...</span>}
      <span className={end === current ? styles.selected : ''} onClick={() => onChange(end)}>
        {end}
      </span>
      {current < end && <span onClick={() => onChange(current + 1)}>{'>'}</span>}
    </div>
  )
}

export default Paginator
