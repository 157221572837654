import React, { useContext, useEffect, useState } from 'react'
import Paginator from '../../components/paginator/paginator.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import { Server } from '../../components/server/server.component'
import StampInfo from '../../components/stamp-info/stamp-info.component'

import styles from './stamps-list.module.css'

const StampsListScreen = () => {
  const { stamps } = useContext(Server)
  const [items, setItems] = useState()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(30)
  const [p, setP] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [filterCat, setFilterCat] = useState('')
  const [filterNo, setFilterNo] = useState('')
  const [filtered, setFiltered] = useState()

  const sort = data => {
    data.sort((a, b) => a.lfdnr - b.lfdnr)
    setItems(data)
  }

  useEffect(() => {
    stamps.load(sort)
  }, [stamps])

  useEffect(() => {
    if (filtered) {
      let l = (filtered.length + pageSize - 1) / pageSize
      setPageCount(parseInt(l < 1 ? 1 : l))
      if (page >= l) {
        setPage(l - 1)
      }
    }
  }, [filtered, pageSize, page])

  useEffect(() => {
    if (filtered) {
      setP(filtered.slice(page * pageSize, (page + 1) * pageSize))
    }
  }, [filtered, page, pageSize])

  useEffect(() => {
    if (items) {
      let f = [...items]
      if (filterCat) {
        const c = filterCat.toUpperCase()
        f = f.filter(v => `^${v.region}$`.includes(c))
      }
      if (filterNo) {
        f = f.filter(v => `^${v.nr}$`.includes(filterNo))
      }
      setFiltered(f)
    }
  }, [filterCat, filterNo, items])

  return (
    <ScreenPanel>
      <Paginator current={page} onChange={setPage} start={0} end={(pageCount - 1) | 0} />
      <div>
        <StampInfo full filter filterCat={filterCat} filterNo={filterNo} onFilterCatChanged={setFilterCat} onFilterNoChanged={setFilterNo} />
        {p && p.map((item, idx) => <StampInfo full key={item.lfdnr + '-' + idx} stamp={item} />)}
      </div>
    </ScreenPanel>
  )
}

export default StampsListScreen
